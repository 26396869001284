@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/* -------------------------------------------------------------------------- */
/* Fonts */
/* -------------------------------------------------------------------------- */
@layer base {
  @font-face {
    font-family: Flanders Art Sans;
    font-weight: theme("fontWeight.normal");
    font-style: normal;
    font-display: swap;

    src: url("@parkour/assets/fonts/FlandersArtSans-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: Flanders Art Sans;
    font-weight: theme("fontWeight.medium");
    font-style: normal;
    font-display: swap;

    src: url("@parkour/assets/fonts/FlandersArtSans-Medium.woff2") format("woff2");
  }
}

/* -------------------------------------------------------------------------- */
/* Application layout */
/* -------------------------------------------------------------------------- */
:root {
  --font-family: Flanders Art Sans, sans-serif !important;
}

body {
  /*@apply typo-body;*/

  color: theme("colors.n-100");
}

/* -------------------------------------------------------------------------- */
/* Application utils */
/* -------------------------------------------------------------------------- */
.break-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.p-toast-message-content {
  border: none
}
